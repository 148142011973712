import { observer } from 'mobx-react-lite'

import { useValueContext } from 'contexts'

import { OptionsBase } from './OptionsBase'
import { Option } from './Option'
import './styles.scss'

interface Props {
  onClose: () => void
}

const filterName = 'projects'

export const ProjectOptions = observer(({ onClose }: Props) => {
  const { config, selectedFilters, setArrayFilter } = useValueContext()
  const { projects } = config

  return (
    <OptionsBase filterName={filterName} onClose={onClose}>
      {projects?.map((it: any) => (
        <Option
          key={it.id}
          value={it.id}
          className="project-label"
          onClick={() => setArrayFilter(filterName, it.id)}
          selected={selectedFilters.projects}
        >
          <div className="project-name">{it.name}</div>
        </Option>
      ))}
    </OptionsBase>
  )
})
