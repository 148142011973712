import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { isDefAndNotEmpty, isTimeValid, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'

import { TimeInput } from '../../components'
import { useBackButton } from '../../hooks'
import { WeekDays } from './WeekDays'

interface Props {
  onValidChange?: (value: boolean) => void
}

export const Weekly = observer(({ onValidChange }: Props) => {
  const { t } = useTranslation()
  const { repeatDaysItems } = useTranslateConsts()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const repeatDays = task?.tempNotification?.repeatDays || []

  const isValidTime = isTimeValid(task?.tempNotification?.start)
  const isValid = isValidTime && isDefAndNotEmpty(repeatDays)

  useEffect(() => {
    onValidChange?.(isValid)
  }, [isValid, onValidChange])

  useBackButton()

  const onSelectDay = (day: string, selected: boolean) => {
    const newDays = selected ? [...repeatDays, day] : repeatDays.filter((it: string) => it !== day)
    setTaskField('tempNotification.repeatDays', newDays)
  }

  return (
    <>
      <div className="repeat-message">
        <Trans
          i18nKey="Многократное напоминание"
          values={{ period: t('в выбранные дни недели') }}
          components={{ 1: <span /> }}
        />
      </div>
      <WeekDays
        days={repeatDaysItems}
        selected={repeatDays}
        onSelect={(value: string, selected: boolean) => onSelectDay(value, selected)}
      />
      <TimeInput />
    </>
  )
})
