import { createBrowserRouter } from 'react-router-dom'

import {
  AppLayout,
  HomeLayout,
  ScrollableContentLayout,
  TaskLayout,
  ProtectedRoute,
  ProjectLayout,
} from 'components'
import * as Page from 'pages'

import { BotActiveRoute } from './pages/Tasks/Personal'

const taskUrl = 'projects/:projectId/tasks/:taskId'
const taskSettingsUrl = `${taskUrl}/settings`

const router = createBrowserRouter([
  {
    path: '/',
    Component: AppLayout,
    children: [
      {
        Component: HomeLayout,
        children: [
          {
            index: true,
            element: <Page.User />,
          },
          {
            path: 'projects',
            element: <Page.Projects />,
          },
        ],
      },
      {
        path: '/tasks-assigned',
        element: <Page.Tasks.Assigned />,
      },
      {
        path: '/tasks-created',
        element: <Page.Tasks.Created />,
      },
      {
        path: '/tasks-personal',
        element: (
          <BotActiveRoute>
            <Page.Tasks.Personal />
          </BotActiveRoute>
        ),
      },
      {
        Component: ProjectLayout,
        children: [
          {
            path: 'projects/:projectId',
            element: <Page.Project />,
          },
          {
            Component: ScrollableContentLayout,
            children: [
              {
                path: 'projects/:projectId/settings',
                element: (
                  <ProtectedRoute>
                    <Page.ProjectSettings />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'projects/:projectId/settings/timezone',
                element: (
                  <ProtectedRoute>
                    <Page.TimeZone />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            Component: TaskLayout,
            children: [
              {
                Component: ScrollableContentLayout,
                children: [
                  {
                    path: taskUrl,
                    element: <Page.Task />,
                  },
                  {
                    path: `${taskSettingsUrl}/repeat`,
                    element: (
                      <ProtectedRoute>
                        <Page.Repeat />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/reminder`,
                    element: (
                      <ProtectedRoute>
                        <Page.Reminder />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/status`,
                    element: (
                      <ProtectedRoute>
                        <Page.Status />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/priority`,
                    element: (
                      <ProtectedRoute>
                        <Page.Priority />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/assignee`,
                    element: (
                      <ProtectedRoute>
                        <Page.Assignee />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/reporter`,
                    element: (
                      <ProtectedRoute>
                        <Page.Reporter />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: `${taskSettingsUrl}/file`,
                    element: (
                      <ProtectedRoute>
                        <Page.FilePreview />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])

export default router
