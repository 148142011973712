import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { Button } from 'components'
import { SortDirection } from 'types'
import { useValueContext } from 'contexts'

import './styles.scss'

const options = [
  {
    label: 'Дата создания',
    value: 'сначала новые',
    key: 'CreatedAt',
    direction: SortDirection.DESC,
  },
  {
    label: 'Дата создания',
    value: 'сначала старые',
    key: 'CreatedAt',
    direction: SortDirection.ASC,
  },
  {
    label: 'Дата изменения',
    value: 'сначала новые',
    key: 'ModifiedAt',
    direction: SortDirection.DESC,
  },
  {
    label: 'Дата изменения',
    value: 'сначала старые',
    key: 'ModifiedAt',
    direction: SortDirection.ASC,
  },
]

interface Props {
  onClose: () => void
}

export const SortOptions = observer(({ onClose }: Props) => {
  const { t } = useTranslation()
  const { sorting } = useValueContext()

  const onSelect = (sortKey: string, sortDirection: SortDirection) => {
    onClose()
    sorting.setSort({ key: sortKey, direction: sortDirection })
  }

  const onClear = () => {
    onClose()
    onSelect('', SortDirection.DESC)
  }

  return (
    <div className="sort-options">
      {options.map(({ label, key, direction, value }) => {
        const isSelected = sorting.key === key && sorting.direction === direction
        return (
          <Button
            mode="plain"
            align="left"
            className={cn('sort-option', { selected: isSelected })}
            onClick={() => onSelect(key, direction)}
            key={`${key}${direction}`}
          >
            <span className="label">{t(label)}</span>: {t(value)}
          </Button>
        )
      })}
      <Button align="left" mode="plain" className="clear-filter-btn" onClick={onClear}>
        {t('Очистить сортировку')}
      </Button>
    </div>
  )
})
