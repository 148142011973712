import { UIEvent, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ConfirmModal, FlexLayout, PageSpinner } from 'components'
import { useValueContext } from 'contexts'

import Header from './Header'
import Form from './Form'
import Footer from './Footer'
import { isFormValid } from './utils'

import './styles.scss'

const Task = observer(() => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { taskStore, projectStore } = useValueContext()
  const { state } = useLocation()
  const ref = useRef<HTMLDivElement>(null)

  const { task, loading, save, remove, isTaskChanged, scrollTop, setScrollTop, setBackRoute } =
    taskStore

  const isValid = isFormValid(task)

  const onScroll = (e: UIEvent<HTMLDivElement>) =>
    setScrollTop((e.target as HTMLDivElement).scrollTop)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = scrollTop
    }
  }, [scrollTop])

  useEffect(() => {
    if (state?.backRoute) {
      setBackRoute(state.backRoute)
    }
  }, [setBackRoute, state?.backRoute])

  return (
    <FlexLayout.Container vertical>
      <PageSpinner open={loading} />
      <Header />

      <FlexLayout.Content ref={ref} onScroll={onScroll} scrollable>
        <Form />
      </FlexLayout.Content>

      <Footer
        onSave={() => save(projectStore?.config?.timeZoneOffset)}
        onDelete={() => setOpen(true)}
        isSaveDisabled={!isValid || !isTaskChanged}
      />

      <ConfirmModal open={open} onSetOpen={setOpen} onActionClick={remove}>
        {t('Вы уверены, что хотите удалить задачу?')}
      </ConfirmModal>
    </FlexLayout.Container>
  )
})

export default Task
