import { FunctionComponent } from 'react'

import {
  AssigneeOptions,
  AuthorOptions,
  ProjectOptions,
  SortOptions,
  StatusOptions,
} from './components'

interface Filter {
  label: string
  Component: FunctionComponent<any>
  apiKey?: string
}

export type Filters = Record<string, Filter>

export const filters: Filters = {
  sort: {
    label: 'Сортировка',
    Component: SortOptions,
  },
  assignee: {
    label: 'Исполнитель',
    apiKey: 'assignees',
    Component: AssigneeOptions,
  },
  status: {
    label: 'Статус',
    apiKey: 'statuses',
    Component: StatusOptions,
  },
  author: {
    label: 'Автор',
    apiKey: 'owners',
    Component: AuthorOptions,
  },
  project: {
    label: 'Проект',
    apiKey: 'projects',
    Component: ProjectOptions,
  },
}
