import { useTranslation } from 'react-i18next'

import { Chip, PriorityIcon } from 'components'
import type { IProjectIssue } from 'types'
import { defaultColor } from 'consts'
import { getDaysAgoString, isToday, useColor } from 'utils'

interface TaskInfoProps {
  number: number
  issue: IProjectIssue
  timeZone?: string
}

const TaskInfo = ({ number, issue }: TaskInfoProps) => {
  const { t } = useTranslation()
  const color = useColor()

  const date = issue.modifiedAt || issue.createdAt
  const isModifiedToday = isToday(new Date(date))
  const finalDate = isModifiedToday ? t('Сегодня') : getDaysAgoString(date, t)
  const issueBackground = issue.status?.[color] || issue.status?.[defaultColor]
  const iconColor = issue.priority?.[color] || issue.priority?.[defaultColor]

  return (
    <div className="task-info">
      <div className="flex-shrink-0"># {number}</div>
      <PriorityIcon fill={iconColor} />
      <Chip className="task-chip" background={issueBackground}>
        {t(issue.status.title)}
      </Chip>
      <div>{finalDate}</div>
      {issue?.owner?.name && (
        <div className="executor">
          <span className="from">От:</span> {issue?.owner?.name}
        </div>
      )}
    </div>
  )
}

export default TaskInfo
