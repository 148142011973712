import { useValueContext } from 'contexts'

import { ScrollableList } from './ScrollableList'

interface Props {
  filterName: string
  onClose: () => void
  children?: React.ReactNode
}

export const OptionsBase = ({ filterName, onClose, children }: Props) => {
  const { clearArrayFilter } = useValueContext()

  const onClearFilter = (filterName: string) => () => {
    clearArrayFilter(filterName)
    onClose()
  }

  return <ScrollableList onClear={onClearFilter(filterName)}>{children}</ScrollableList>
}
