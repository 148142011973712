import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { ArrowIcon, Button } from 'components'
import { ButtonProps } from 'components/UI/Button'

import './styles.scss'

export interface DropdownOption {
  id: number
  name: string
}

interface Props<T> extends Omit<ButtonProps, 'label' | 'value' | 'onChange'> {
  value: T
  label?: React.ReactNode
  className?: string
  objectValue?: boolean
  staticMenu?: boolean
  options: DropdownOption[]
  valueKey?: keyof DropdownOption
  labelKey?: keyof DropdownOption
  maxHeight?: number
  onChange: (value: T) => void
}

type DropdownValueType = string | number | DropdownOption

export const Dropdown = <T extends DropdownValueType>(props: Props<T>) => {
  const {
    label,
    value,
    className,
    options,
    staticMenu,
    objectValue,
    valueKey = 'id',
    labelKey = 'name',
    maxHeight = 250,
    onChange,
    ...rest
  } = props
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  const selectedOption = options.find((it) => {
    if (objectValue) {
      return it?.[valueKey] === (value as DropdownOption)?.[valueKey]
    }
    return it?.[valueKey] === value
  })

  const displayedValue = selectedOption?.[labelKey]

  const onChangeHandler = (value: DropdownOption) => {
    const newValue = objectValue ? value : (value as DropdownOption)?.[valueKey]
    onChange(newValue as T)
    setOpen(false)
  }

  return (
    <div className={cn('dropdown-select', className)} ref={ref}>
      {label && <span className="dropdown-label">{label}</span>}
      <Button
        align="between"
        className="dropdown-button"
        {...rest}
        after={<ArrowIcon className="dropdown-button-arrow" />}
        onClick={() => setOpen((prev) => !prev)}
      >
        {displayedValue}
      </Button>
      {open && (
        <div className={cn('dropdown-options', { static: staticMenu })}>
          <div className="options-inner" style={{ maxHeight }}>
            {options?.map((it) => (
              <div className="dropdown-option" key={it.id} onClick={() => onChangeHandler(it)}>
                {it.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
