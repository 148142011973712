import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'
import { observer } from 'mobx-react-lite'

import { TaskStore } from 'stores'
import { useValueContext, ValueContext } from 'contexts'
import { useApp } from 'utils'

export const TaskLayout = observer(() => {
  const { projectId, taskId } = useParams()
  const navigate = useNavigate()
  const projectStore = useValueContext()
  const { pathname } = useLocation()
  const { initData } = useApp()

  const [store] = useState(() => new TaskStore(projectId || '', taskId || '', navigate))

  useEffect(() => {
    if (!projectStore.isEmptyConfig && !projectStore.isEmptyUsers) {
      store.init(projectStore.config, projectStore.users, initData?.user)
    }
  }, [
    initData?.user,
    projectStore.config,
    projectStore.isEmptyConfig,
    projectStore.isEmptyUsers,
    projectStore.users,
    store,
  ])

  const isTaskRoute = pathname === `/projects/${projectId}/tasks/${taskId}`

  const onBackClick = () => {
    const route: any = isTaskRoute ? store.backRoute : -1
    navigate(route)
  }

  return (
    <ValueContext.Provider value={{ taskStore: store, projectStore }}>
      <BackButton onClick={onBackClick} />
      <Outlet />
    </ValueContext.Provider>
  )
})
