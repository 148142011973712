import { useEffect } from 'react'
import WebApp from '@twa-dev/sdk'
import { useNavigate, useParams } from 'react-router-dom'

import { useValueContext } from 'contexts'

export const useBackButton = () => {
  const navigate = useNavigate()
  const { projectId, taskId } = useParams()
  const { taskStore } = useValueContext()
  const { setTaskField, task } = taskStore

  useEffect(() => {
    const onBackClick = () => {
      setTaskField('tempNotification', { ...task?.notification })

      navigate(`/projects/${projectId}/tasks/${taskId}/settings/repeat`)
    }

    WebApp.BackButton.onClick(onBackClick)

    return () => {
      WebApp.BackButton.offClick(onBackClick)
    }
  }, [navigate, projectId, setTaskField, task?.notification, taskId])
}
