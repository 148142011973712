import { ReactNode, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import { useValueContext, ValueContext } from 'contexts'
import { isDefAndNotEmpty, useInfiniteScroll } from 'utils'
import { FlexLayout, PageSpinner, SearchInput, TaskFilters } from 'components'
import { Filters } from 'components/TaskFilters/filters'

import './styles.scss'

interface Props {
  filterKeys: (keyof Filters)[]
  onSearch: () => void
  loading?: boolean
  contentClassName?: string
  Header?: ReactNode
  Tasks?: ReactNode
  Footer?: ReactNode
}

export const TaskListBase = observer((props: Props) => {
  const { filterKeys, onSearch, loading = false, contentClassName, Header, Tasks, Footer } = props
  const store = useValueContext()
  const listRef = useRef<HTMLDivElement>(null)

  const { loadMoreIssues, setSearch, search, issues, pagination, loadingMore, hasFilters } = store

  useEffect(() => {
    pagination.setFirstPage()
  }, [pagination])

  useEffect(() => {
    if (pagination.currentPage > 1) {
      loadMoreIssues()
    }
  }, [pagination.currentPage, loadMoreIssues])

  useEffect(() => {
    if (!listRef.current) {
      return
    }
    if (isDefAndNotEmpty(issues) && pagination.isFirstPage) {
      listRef.current.scrollTop = 0
    }
  }, [issues, pagination.isFirstPage])

  const onScrollToBottom = () => {
    pagination.setNextPage()
  }
  const onScroll = useInfiniteScroll(loadingMore, onScrollToBottom)

  const showFilters = hasFilters || isDefAndNotEmpty(issues)

  return (
    <ValueContext.Provider value={store}>
      <PageSpinner open={loading} />
      <FlexLayout.Container vertical>
        {Header}

        {showFilters && (
          <div className="px-3">
            <SearchInput value={search} onChange={setSearch} onSearch={onSearch} />
            <TaskFilters filterKeys={filterKeys} />
          </div>
        )}

        <FlexLayout.Content
          className={contentClassName}
          onScroll={onScroll}
          ref={listRef}
          scrollable
        >
          {Tasks}
        </FlexLayout.Content>
        {Footer}
      </FlexLayout.Container>
    </ValueContext.Provider>
  )
})
