import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Input } from '@telegram-apps/telegram-ui'
import Inputmask from 'inputmask'

import { useValueContext } from 'contexts'

export const TimeInput = observer(() => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore

  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      Inputmask({ mask: '99:99' }).mask(node)
    }
  }, [])

  return (
    <div className="task-input-wrapper select-option reminder-time">
      <span className="select-label">{t('Время')}</span>
      <Input
        ref={ref}
        placeholder="00:00"
        className="task-input"
        value={task?.tempNotification?.start || ''}
        onChange={(e) => setTaskField('tempNotification.start', e.target.value)}
      />
    </div>
  )
})
