import cn from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FlexLayout, OptionCard, PageHeader } from 'components'
import { toZoned, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'

import './styles.scss'

const getDefaultNotificationValues = (
  timeZone: string = '',
): Record<string, Record<string, any>> => {
  return {
    Never: {
      type: 'Never',
    },
    Once: {
      type: 'Once',
      start: '',
      date: toZoned(new Date(), timeZone),
    },
    Daily: {
      type: 'Daily',
      start: '',
    },
    Weekly: {
      type: 'Weekly',
      start: '',
      repeatDays: [],
    },
    Monthly: {
      type: 'Monthly',
      start: '',
      isLastDay: false,
      repeatDates: [],
    },
    Yearly: {
      type: 'Yearly',
      start: '',
      month: 1,
      day: 1,
    },
  }
}

export const Repeat = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectId, taskId } = useParams()
  const { repeatTimeItems } = useTranslateConsts()
  const { taskStore, projectStore } = useValueContext()

  const { task, setTaskField } = taskStore

  const defaultNotificationValues = getDefaultNotificationValues(
    projectStore?.config?.timeZoneOffset,
  )

  const repeatType = task?.notification?.type
  const taskUrl = `/projects/${projectId}/tasks/${taskId}`

  const onSelectType = (id: string) => {
    if (id !== task?.notification?.type) {
      setTaskField('tempNotification', defaultNotificationValues[id])
    } else {
      setTaskField('tempNotification', { ...task?.notification })
    }

    if (id === 'Never') {
      setTaskField('notification', defaultNotificationValues.Never)
      navigate(taskUrl)
    } else {
      navigate(`${taskUrl}/settings/reminder`)
    }
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Напоминание')}</PageHeader>
      <FlexLayout.Content scrollable>
        <div className="button-container">
          {repeatTimeItems.map((it) => (
            <OptionCard
              key={it.id}
              className={cn('button-repeat-type', {
                active: repeatType === it.id,
              })}
              onClick={() => onSelectType(it.id)}
            >
              {it.name}
            </OptionCard>
          ))}
        </div>
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})
