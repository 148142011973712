import { observer } from 'mobx-react-lite'

import { useValueContext } from 'contexts'
import { ITaskPerson } from 'types'

import { OptionsBase } from './OptionsBase'
import { Option } from './Option'
import './styles.scss'

interface Props {
  onClose: () => void
}

const filterName = 'owners'

export const AuthorOptions = observer(({ onClose }: Props) => {
  const { users, selectedFilters, setArrayFilter } = useValueContext()

  return (
    <OptionsBase filterName={filterName} onClose={onClose}>
      {users?.map((it: ITaskPerson) => (
        <Option
          key={it.id}
          value={it.id}
          className="author-label"
          onClick={() => setArrayFilter(filterName, it.id)}
          selected={selectedFilters.owners}
        >
          <div className="author-name">{it.name}</div>
        </Option>
      ))}
    </OptionsBase>
  )
})
