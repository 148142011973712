import { useEffect } from 'react'
import { Checkbox } from '@telegram-apps/telegram-ui'
import { Trans, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { isDefAndNotEmpty, isTimeValid } from 'utils'
import { useValueContext } from 'contexts'

import { TimeInput } from '../../components'
import { useBackButton } from '../../hooks'
import './styles.scss'

interface Props {
  onValidChange?: (value: boolean) => void
}

const daysOfMonth = Array.from({ length: 30 }, (_, index) => index + 1)

export const Monthly = observer(({ onValidChange }: Props) => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const repeatDates = task?.tempNotification?.repeatDates || []
  const isLastDay = task?.tempNotification?.isLastDay

  const isValidTime = isTimeValid(task?.tempNotification?.start)
  const isDaySelected = isDefAndNotEmpty(repeatDates) || isLastDay
  const isValid = isValidTime && isDaySelected

  useEffect(() => {
    onValidChange?.(isValid)
  }, [isValid, onValidChange])

  useBackButton()

  const onToggleDay = (day: number) => {
    const newDays = [...repeatDates]

    if (newDays.includes(day)) {
      newDays.splice(newDays.indexOf(day), 1)
    } else {
      newDays.push(day)
    }
    setTaskField('tempNotification.repeatDates', newDays)
  }

  return (
    <>
      <div className="repeat-message">
        <Trans
          i18nKey="Многократное напоминание"
          values={{ period: t('в выбранные дни месяца') }}
          components={{ 1: <span /> }}
        />
      </div>

      <div className="monthly-days">
        {daysOfMonth.map((day) => (
          <div
            key={day}
            className={cn('day', { selected: repeatDates.includes(day) })}
            onClick={() => onToggleDay(day)}
          >
            {day}
          </div>
        ))}
      </div>
      <div
        className="monthly-lastday"
        onClick={() => setTaskField('tempNotification.isLastDay', !isLastDay)}
      >
        <Checkbox className="checkbox" checked={isLastDay} onChange={() => {}} />
        <div className="label">Последний день месяца</div>
      </div>

      <TimeInput />
    </>
  )
})
