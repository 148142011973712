import { apiService } from 'services'
import { AxiosResponse } from 'axios'
import { BasicGetParams, ProjectIssuesResponse } from '../types'

const URL = '/me'
const ISSUES_URL = '/issues'
const FILTERS_URL = `${URL}/filters`
const ASSIGNED_URL = '/assigned'
const CREATED_URL = '/owned'
const PERSONAL_URL = '/myproject'

const isBotInContacts = async () => {
  return apiService.get<AxiosResponse<{ data: boolean }>>(`${URL}/ismyprojectexists`)
}

const getAssignedTasks = async (params: BasicGetParams) => {
  return apiService.get<AxiosResponse<ProjectIssuesResponse>>(
    `${URL}${ISSUES_URL}${ASSIGNED_URL}`,
    {
      params,
    },
  )
}

const getCreatedTasks = async (params: BasicGetParams) => {
  return apiService.get<AxiosResponse<ProjectIssuesResponse>>(`${URL}${ISSUES_URL}${CREATED_URL}`, {
    params,
  })
}

const getPersonalTasks = async (params: BasicGetParams) => {
  return apiService.get<AxiosResponse<ProjectIssuesResponse>>(
    `${URL}${ISSUES_URL}${PERSONAL_URL}`,
    {
      params,
    },
  )
}

const getAssignedFilters = async () => {
  return apiService.get<AxiosResponse<any>>(`${FILTERS_URL}${ASSIGNED_URL}`)
}

const getCreatedFilters = async () => {
  return apiService.get<AxiosResponse<any>>(`${FILTERS_URL}${CREATED_URL}`)
}

const getPersonalFilters = async () => {
  return apiService.get<AxiosResponse<any>>(`${FILTERS_URL}${PERSONAL_URL}`)
}

export const UserApi = {
  isBotInContacts,
  getAssignedTasks,
  getAssignedFilters,
  getPersonalFilters,
  getCreatedTasks,
  getCreatedFilters,
  getPersonalTasks,
}
