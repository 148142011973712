import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { TaskListBase } from 'blocks'
import { PageHeader, PageSpinner, TaskCreateFooter } from 'components'

import TaskList from './TaskList'

import { TasksStore } from 'stores'
import { ValueContext } from 'contexts'
import { isAllDef } from 'utils'

const filterKeys = ['sort', 'status']

const Personal = observer(() => {
  const [store] = useState(() => new TasksStore.TasksPersonalStore())

  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    sorting,
    pagination,
    loadConfig,
    issues,
    selectedFilters,
    loadIssues,
    saveFilters,
    loadingMore,
    loading,
    issuesLoading,
    issuesLoaded,
    configLoaded,
    hasFilters,
    projectId,
  } = store

  const { statuses } = selectedFilters

  useEffect(() => {
    loadConfig()
  }, [loadConfig])

  useEffect(() => {
    const shouldLoadIssues = isAllDef(sorting.key, sorting.direction, statuses) && configLoaded

    if (shouldLoadIssues) {
      pagination.setFirstPage()
      saveFilters()
      loadIssues()
    }
  }, [configLoaded, loadIssues, pagination, saveFilters, sorting.direction, sorting.key, statuses])

  const onBackClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const Header = (
    <>
      <BackButton onClick={onBackClick} />
      <PageHeader>{t('Мои личные задачи')}</PageHeader>
    </>
  )

  const Tasks = (
    <TaskList
      items={issues}
      loaded={issuesLoaded}
      loading={issuesLoading}
      hasFilters={hasFilters}
      loadingMore={loadingMore || issuesLoading}
    />
  )

  const Footer =
    projectId === 0 ? null : <TaskCreateFooter to={`/projects/${projectId}/tasks/new`} />

  return (
    <ValueContext.Provider value={store}>
      <PageSpinner open={loading} />
      <TaskListBase
        filterKeys={filterKeys}
        onSearch={loadIssues}
        Header={Header}
        Tasks={Tasks}
        Footer={Footer}
      />
    </ValueContext.Provider>
  )
})

export default Personal
