import { makeAutoObservable } from 'mobx'
import { WebAppUser } from '@twa-dev/types'

import { getFullName } from './utils'

export default class UserStore {
  firstName: string
  lastName?: string
  avatar?: string

  constructor(tgUser?: WebAppUser) {
    // Если есть tgUser - приложение запущено в Telegram, иначе в браузере
    if (tgUser) {
      this.firstName = tgUser.first_name
      this.lastName = tgUser.last_name
      // Добавляем случайное число, чтобы избежать кеша аватара (актуально при смене аватара)
      this.avatar = tgUser.photo_url ? `${tgUser.photo_url}?${Math.random()}` : undefined
    } else {
      this.firstName = 'Unknown User'
    }

    makeAutoObservable(this)
  }

  get fullName() {
    return getFullName(this.firstName, this.lastName)
  }
}
