import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Calendar } from 'components'
import { getIanaTimeZone, isTimeValid, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'

import { TimeInput } from '../components'
import { useBackButton } from '../hooks'

interface Props {
  onValidChange?: (value: boolean) => void
}

export const Once = observer(({ onValidChange }: Props) => {
  const { t } = useTranslation()
  const { monthNames, dayNames } = useTranslateConsts()

  const { projectStore, taskStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const { config } = projectStore

  const isValidTime = isTimeValid(task?.tempNotification?.start)

  useEffect(() => {
    onValidChange?.(isValidTime)
  }, [isValidTime, onValidChange])

  useBackButton()

  return (
    <>
      <div className="repeat-message">
        <Trans
          i18nKey="Однократное напоминание"
          values={{ period: t('в выбранную дату и время') }}
          components={{ 1: <span /> }}
        />
      </div>
      <Calendar
        timeZone={getIanaTimeZone(config?.timeZoneOffset)}
        value={task?.tempNotification?.date}
        onChange={(date) => {
          setTaskField('tempNotification.date', date)
        }}
        monthNames={monthNames}
        dayNames={dayNames}
        className="reminder-calendar"
        disablePast
      />
      <TimeInput />
    </>
  )
})
