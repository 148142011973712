import { useTranslation } from 'react-i18next'

import { Chip, PriorityIcon } from 'components'
import type { IProjectIssue } from 'types'
import { defaultColor } from 'consts'
import { getCountCase, getDiffInDaysFromCurrent, isTodayAccordingToTimeZone, useColor } from 'utils'
import { TFunction } from 'i18next'

interface TaskInfoProps {
  number: number
  issue: IProjectIssue
  timeZone?: string
}

const getDaysAgoString = (date: string, timeZone: string, t: TFunction): string => {
  const daysAgoCount = getDiffInDaysFromCurrent(date, timeZone)
  const daysAgoWord = getCountCase(daysAgoCount, ['день', 'дня', 'дней'])

  return t('дни назад', { count: daysAgoCount, days: daysAgoWord })
}

const TaskInfo = ({ number, issue, timeZone = '' }: TaskInfoProps) => {
  const { t } = useTranslation()
  const color = useColor()

  const date = issue.modifiedAt || issue.createdAt
  const isModifiedToday = isTodayAccordingToTimeZone(date, timeZone)
  const finalDate = isModifiedToday ? t('Сегодня') : getDaysAgoString(date, timeZone, t)
  const issueBackground = issue.status?.[color] || issue.status?.[defaultColor]
  const iconColor = issue.priority?.[color] || issue.priority?.[defaultColor]

  return (
    <div className="task-info">
      <div className="flex-shrink-0"># {number}</div>
      <PriorityIcon fill={iconColor} />
      <Chip className="task-chip" background={issueBackground}>
        {t(issue.status.title)}
      </Chip>
      <div>{finalDate}</div>
      <div className="executor">{issue?.assignedTo?.name || t('Не назначена')}</div>
    </div>
  )
}

export default TaskInfo
