import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { useColor } from 'utils'
import { DictValue } from 'types'
import { defaultColor } from 'consts'
import { useValueContext } from 'contexts'

import { OptionsBase } from './OptionsBase'
import { Option } from './Option'
import './styles.scss'

interface Props {
  onClose: () => void
}

const filterName = 'statuses'

export const StatusOptions = observer(({ onClose }: Props) => {
  const color = useColor()
  const { config, selectedFilters, setArrayFilter } = useValueContext()
  const { t } = useTranslation()

  const { statuses } = config
  const tranlatedStatuses = statuses.map((it: DictValue) => ({
    ...it,
    title: t(it.title),
  }))

  return (
    <OptionsBase filterName={filterName} onClose={onClose}>
      {tranlatedStatuses?.map((it: DictValue) => {
        const backgroundColor = it[color] || it[defaultColor]

        return (
          <Option
            key={it.id}
            value={it.id as number}
            className="status-label"
            onClick={() => setArrayFilter(filterName, it.id)}
            selected={selectedFilters.statuses}
          >
            <div className="d-flex align-items-center">
              <span className="status-badge" style={{ backgroundColor }} />
              <span className="status-name">{it.title}</span>
            </div>
          </Option>
        )
      })}
    </OptionsBase>
  )
})
