import { observer } from 'mobx-react-lite'

import { AssigneeLabel } from 'components'
import { useValueContext } from 'contexts'
import { ITaskPerson } from 'types'

import { OptionsBase } from './OptionsBase'
import { Option } from './Option'
import './styles.scss'

interface Props {
  onClose: () => void
}

const filterName = 'assignees'

export const AssigneeOptions = observer(({ onClose }: Props) => {
  const { users, selectedFilters, setArrayFilter } = useValueContext()

  return (
    <OptionsBase filterName={filterName} onClose={onClose}>
      {users?.map((it: ITaskPerson) => {
        return (
          <Option
            key={it.id}
            value={it.id}
            className="assignee-label"
            onClick={() => setArrayFilter(filterName, it.id)}
            selected={selectedFilters.assignees}
          >
            <AssigneeLabel data={it} />
          </Option>
        )
      })}
    </OptionsBase>
  )
})
