import { ReactNode } from 'react'
import { Checkbox } from '@telegram-apps/telegram-ui'

interface Props {
  onClick: () => void
  selected: number[]
  value: number
  className?: string
  children?: ReactNode
}

export const Option = (props: Props) => {
  const { onClick, selected, value, className, children } = props

  return (
    <div className={className} onClick={onClick}>
      {children}
      <Checkbox
        className="filter-list-checkbox"
        checked={selected.includes(value)}
        onChange={() => {}}
      />
    </div>
  )
}
