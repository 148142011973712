export const isFormValid = (task: any) => {
  if (!task) return false

  const hasTitle = task?.title?.trim()?.length >= 5

  return (
    hasTitle &&
    task?.isNotificationValid &&
    [task.status, task.priority, task.type].every((value) => value != null)
  )
}
