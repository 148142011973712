import { ComponentType, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FlexLayout, PageHeader } from 'components'
import { useValueContext } from 'contexts'

import { Once, Daily, Weekly, Monthly, Yearly } from './screens'
import './styles.scss'

const screensMap: Record<string, ComponentType<{ onValidChange?: (value: boolean) => void }>> = {
  Once,
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

export const Reminder = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectId, taskId } = useParams()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore

  const repeatType = task?.tempNotification?.type
  const taskUrl = `/projects/${projectId}/tasks/${taskId}`
  const Component = screensMap[repeatType]

  useEffect(() => {
    if (!Component) {
      navigate(taskUrl)
    }
  }, [Component, navigate, taskUrl])

  if (!Component) {
    return null
  }

  const onValidChange = (value: boolean) => setTaskField('isNotificationValid', value)

  const onApply = () => {
    setTaskField('notification', { ...task?.tempNotification })
    navigate(taskUrl)
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Напоминание')}</PageHeader>
      <FlexLayout.Content scrollable>
        <Component onValidChange={onValidChange} />
      </FlexLayout.Content>

      <div className="operations-buttons">
        <Button
          className="operation-button save"
          disabled={!task?.isNotificationValid}
          onClick={onApply}
        >
          {t('Применить')}
        </Button>
      </div>
    </FlexLayout.Container>
  )
})
