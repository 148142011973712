import { useState } from 'react'
import cn from 'classnames'
import { Modal, Placeholder } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { ArrowIcon, SortIcon, Button } from 'components'
import { useValueContext } from 'contexts'
import { isDefAndNotEmpty } from 'utils'
import { NString } from 'types'

import { Filters, filters } from './filters'
import './styles.scss'

interface Props {
  filterKeys: (keyof Filters)[]
}

export const TaskFilters = observer(({ filterKeys }: Props) => {
  const { t } = useTranslation()

  const [currentModal, setCurrentModal] = useState<NString>(null)
  const [open, setOpen] = useState(false)
  const { selectedFilters, sorting, resetFilters } = useValueContext()

  const onOpenModal = (currentState: string) => {
    setCurrentModal(currentState)
    setOpen(true)
  }

  const Component = filters[currentModal as string]?.Component

  return (
    <div className="tasks-filters-wrapper">
      <Modal
        open={open}
        onOpenChange={(state) => setOpen(state)}
        className="modal-rounded"
        dismissible
      >
        <Placeholder>{Component && <Component onClose={() => setOpen(false)} />}</Placeholder>
      </Modal>
      <div className="tasks-filters">
        {filterKeys.map((key) => {
          const filter = filters[key]

          return key === 'sort' ? (
            <Button
              key={key}
              mode="outline"
              onClick={() => onOpenModal(key)}
              className={cn('filter-button', { selected: isDefAndNotEmpty(sorting.key) })}
              before={<SortIcon />}
            >
              {t(filter.label)}
            </Button>
          ) : (
            <Button
              key={key}
              mode="outline"
              onClick={() => onOpenModal(key)}
              className={cn('filter-button', {
                selected: isDefAndNotEmpty(selectedFilters[filter.apiKey as string]),
              })}
              after={<ArrowIcon className="filter-dropdown-icon" />}
            >
              {t(filter.label)}
            </Button>
          )
        })}
        <Button mode="plain" className="filter-button reset-filters-button" onClick={resetFilters}>
          {t('Очистить фильтры')}
        </Button>
      </div>
    </div>
  )
})
