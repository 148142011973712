import { action, makeObservable, observable } from 'mobx'
import WebApp from '@twa-dev/sdk'

import { PaginationStore, SortingStore } from 'stores/shared'
import { isDef, isDefAndNotEmpty, UserSettingsManager } from 'utils'
import {
  BasicGetParams,
  IProjectConfig,
  IProjectIssue,
  ITaskPerson,
  NString,
  SelectedFilters,
} from 'types'

export const defaultConfig = {
  statuses: [],
  types: [],
  priorities: [],
  timeZoneOffset: '',
}

export default class BaseTasksStore {
  issues: IProjectIssue[] = []
  selectedFilters: SelectedFilters = {}
  initialSelectedFilters: SelectedFilters = {}
  loading = true
  issuesLoading = false
  issuesLoaded = false
  loadingMore = false
  search: NString = null
  settingsKey = ''
  sorting
  pagination
  settingsManager
  // Справочные значения
  config: any = defaultConfig
  users: ITaskPerson[] = []

  constructor() {
    this.sorting = new SortingStore()
    this.pagination = new PaginationStore({})

    const userId = WebApp?.initDataUnsafe?.user?.id?.toString() || ''
    this.settingsManager = new UserSettingsManager(userId)

    makeObservable(this, {
      issues: observable,
      selectedFilters: observable,
      initialSelectedFilters: observable,
      loading: observable,
      issuesLoading: observable,
      issuesLoaded: observable,
      loadingMore: observable,
      search: observable,
      settingsKey: observable,
      sorting: observable,
      pagination: observable,
      settingsManager: observable,
      config: observable,
      users: observable,
      setSearch: action,
      resetFilters: action,
      applySavedFilters: action,
      saveFilters: action,
      setArrayFilter: action,
      clearArrayFilter: action,
    })
  }

  get hasFilters() {
    return (
      isDef(this.search) ||
      isDef(this.sorting.getSort()) ||
      Object.values(this.selectedFilters).some(isDefAndNotEmpty)
    )
  }

  get isEmptyConfig() {
    return Object.keys(this.config).every(
      (it) => !isDefAndNotEmpty(this.config[it as keyof IProjectConfig]),
    )
  }

  get isEmptyUsers() {
    return !isDefAndNotEmpty(this.users)
  }

  get enabledUsers() {
    return this.users.filter((user) => !!user.enabled)
  }

  setSearch = (value: string) => {
    this.search = value
  }

  resetFilters = () => {
    this.selectedFilters = JSON.parse(JSON.stringify(this.initialSelectedFilters))
    this.sorting.resetSort()
  }

  applySavedFilters = () => {
    const savedFilters = this.settingsManager.getByPath(`${this.settingsKey}.filters`)

    if (savedFilters) {
      this.selectedFilters = savedFilters
    }

    const savedSorting = this.settingsManager.getByPath(`${this.settingsKey}.sorting`)

    if (savedSorting) {
      this.sorting.setSort(savedSorting)
    }
  }

  saveFilters = () => {
    this.settingsManager.saveByPath(`${this.settingsKey}.filters`, this.selectedFilters)
    this.settingsManager.saveByPath(`${this.settingsKey}.sorting`, {
      key: this.sorting.key,
      direction: this.sorting.direction,
    })
  }

  setArrayFilter = (field: keyof SelectedFilters, value: string | number) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }

    const selectedValue = this.selectedFilters[field]?.find((it) => it === value)

    if (!isDef(selectedValue)) {
      this.selectedFilters[field] = [...this.selectedFilters[field]!, value]
    } else {
      this.selectedFilters[field] = this.selectedFilters[field]?.filter((it) => it !== value)
    }
  }

  clearArrayFilter = (field: keyof SelectedFilters) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }
    this.selectedFilters[field] = []
  }

  getListParams = (): BasicGetParams => {
    return {
      Page: this.pagination.currentPage,
      Size: this.pagination.pageSize,
      Sorts: this.sorting.getSort(),
      Title: isDefAndNotEmpty(this.search) ? this.search : undefined,
      ...this.selectedFilters,
    }
  }
}
