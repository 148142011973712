import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NoData, Spinner } from 'components'
import type { IProjectIssue } from 'types'

import TaskItem from './TaskItem'

interface TaskListProps {
  items: IProjectIssue[]
  loading: boolean
  loaded: boolean
  loadingMore: boolean
  hasFilters?: boolean
  timeZone?: string
}

const TaskList = ({
  items = [],
  loading = false,
  loaded = false,
  loadingMore = false,
  hasFilters,
}: TaskListProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onItemClick = useCallback(
    (projectId: number, issueId: number) => {
      if (issueId)
        navigate(`/projects/${projectId}/tasks/${issueId}`, {
          state: { backRoute: '/tasks-personal' },
        })
    },
    [navigate],
  )

  const emptyJsx =
    loading || !loaded ? null : (
      <NoData>{t(hasFilters ? 'Ничего не найдено' : 'Пока что у вас нет задач')}</NoData>
    )

  return (
    <>
      <Spinner className="list-spinner" variant="sm" open={loadingMore} />
      {items.length ? (
        <ul className="task-list">
          {items.map((issue, index) => (
            <TaskItem key={issue.id} number={index + 1} issue={issue} onClick={onItemClick} />
          ))}
        </ul>
      ) : (
        emptyJsx
      )}
    </>
  )
}

export default TaskList
