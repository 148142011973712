import { useCallback } from 'react'

import type { IProjectIssue } from 'types'

import TaskInfo from './TaskInfo'

interface TaskItemProps {
  number: number
  issue: IProjectIssue
  onClick?: (projectId: number, taskId: number) => void
}

const TaskItem = ({ number, issue, onClick }: TaskItemProps) => {
  const handleClick = useCallback(() => {
    onClick?.(issue.project?.id, issue.id)
  }, [issue, onClick])

  return (
    <li className="task-item" onClick={handleClick}>
      <div className="title">{issue.title}</div>
      <TaskInfo number={number} issue={issue} />
    </li>
  )
}

export default TaskItem
