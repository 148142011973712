import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { isDef, isTimeValid, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'
import { Dropdown } from 'components'
import { DropdownOption } from 'components/UI/Dropdown'

import { TimeInput } from '../components'
import { useBackButton } from '../hooks'

interface Props {
  onValidChange?: (value: boolean) => void
}

const getDaysInMonth = (month: number): number[] => {
  const currentYear = new Date().getFullYear()
  // Месяц передаем с 1, поэтому день ставим 0.
  // 0 день следующего месяца = последний день текущего месяца
  const date = new Date(currentYear, month, 0)
  const daysCount = date.getDate()

  return Array.from({ length: daysCount }, (_, index) => index + 1)
}

const generateDaysOptions = (month: number = 1): DropdownOption[] => {
  const monthDays = getDaysInMonth(month)

  return monthDays.map((it) => ({
    id: it,
    name: it.toString().padStart(2, '0'),
  }))
}

export const Yearly = observer(({ onValidChange }: Props) => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { monthNames } = useTranslateConsts()
  const { task, setTaskField, setTempNotificationFields } = taskStore
  const [daysOptions, setDaysOptions] = useState<DropdownOption[]>(() => generateDaysOptions())

  const monthsOptions = useMemo(() => {
    return monthNames.map((it, index) => ({
      id: index + 1,
      name: it,
    }))
  }, [monthNames])

  const onMonthChange = (value: number) => {
    setTempNotificationFields({
      month: value,
      day: 1,
    })
    setDaysOptions(generateDaysOptions(value))
  }

  const isValidTime = isTimeValid(task?.tempNotification?.start)
  const isValid =
    isValidTime && isDef(task?.tempNotification?.month) && isDef(task?.tempNotification?.day)

  useEffect(() => {
    onValidChange?.(isValid)
  }, [isValid, onValidChange])

  useBackButton()

  return (
    <>
      <div className="repeat-message">
        <Trans
          i18nKey="Многократное напоминание"
          values={{ period: t('ежегодно в выбранную дату и время') }}
          components={{ 1: <span /> }}
        />
      </div>
      <Dropdown
        label={t('Месяц')}
        value={task?.tempNotification?.month}
        options={monthsOptions}
        onChange={onMonthChange}
        staticMenu
      />
      <Dropdown
        label={t('День')}
        value={task?.tempNotification?.day}
        options={daysOptions}
        onChange={(value) => setTaskField('tempNotification.day', value)}
        staticMenu
      />
      <TimeInput />
    </>
  )
})
