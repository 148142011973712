import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Input } from '@telegram-apps/telegram-ui'

import { PrioritySelect, Select, StatusSelect } from 'components'
import { useColor, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'
import { defaultColor } from 'consts'

import { Editor } from './components'

const Form = observer(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const color = useColor()
  const { repeatTimeItems } = useTranslateConsts()
  const { taskStore } = useValueContext()
  const { projectId, task, setTaskField, isNewTask } = taskStore
  const repeatValue = repeatTimeItems.find((it) => it.id === task?.notification?.type)?.name
  const statusColor = task?.status?.[color] || task?.status?.[defaultColor]
  const isPersonal = Number(projectId) < 0

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskField('title', e.target.value)
  }

  const onDescriptionChange = (value: string) => {
    setTaskField('description', value)
  }

  return (
    <>
      <div className="task-input-wrapper">
        <Input
          value={task?.title || ''}
          placeholder={`${t('Заголовок задачи')} *`}
          className="task-input"
          onChange={onTitleChange}
        />
      </div>
      <Editor value={task?.description} onChange={onDescriptionChange} />
      <StatusSelect
        value={t(task?.status?.title)}
        color={statusColor}
        onClick={() => navigate('settings/status')}
      />
      <Select
        label={t('Напоминание (повторяющаяся задача)')}
        value={repeatValue || ''}
        onClick={() => navigate('settings/repeat')}
      />

      {!isPersonal && (
        <>
          <Select
            label={t('Исполнитель')}
            value={task?.assignedTo?.name || task?.assignedTo?.login || t('Не назначен')}
            onClick={() => navigate('settings/assignee')}
          />
          {!isNewTask && (
            <Select
              label={t('Автор')}
              value={task?.owner?.name}
              disabled
              onClick={() => navigate('settings/reporter')}
            />
          )}
        </>
      )}

      <PrioritySelect
        value={task?.priority}
        color={statusColor}
        onClick={() => navigate('settings/priority')}
      />
    </>
  )
})

export default Form
