import { useTranslation } from 'react-i18next'

import { BottomFooter, CreateButton } from 'components'

interface Props {
  to?: string
}

export const TaskCreateFooter = ({ to = 'tasks/new' }: Props) => {
  const { t } = useTranslation()

  return (
    <BottomFooter className="mb-3">
      <CreateButton to={to}>{t('Создать задачу')}</CreateButton>
    </BottomFooter>
  )
}
