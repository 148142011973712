import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { isTimeValid } from 'utils'
import { useValueContext } from 'contexts'

import { TimeInput } from '../components'
import { useBackButton } from '../hooks'

interface Props {
  onValidChange?: (value: boolean) => void
}

export const Daily = observer(({ onValidChange }: Props) => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { task } = taskStore

  const isValidTime = isTimeValid(task?.tempNotification?.start)

  useEffect(() => {
    onValidChange?.(isValidTime)
  }, [isValidTime, onValidChange])

  useBackButton()

  return (
    <>
      <div className="repeat-message">
        <Trans
          i18nKey="Многократное напоминание"
          values={{ period: t('Ежедневно').toLowerCase() }}
          components={{ 1: <span /> }}
        />
      </div>

      <TimeInput />
    </>
  )
})
