import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'
import { observer } from 'mobx-react-lite'

import { isAllDef } from 'utils'
import { TaskListBase } from 'blocks'
import { useValueContext } from 'contexts'
import { GearIcon, HeaderWithControl, TaskCreateFooter } from 'components'

import TaskList from './TaskList'
import './styles.scss'

const filterKeys = ['sort', 'assignee', 'status', 'author']

const Project = observer(() => {
  const store = useValueContext()
  const navigate = useNavigate()
  const { projectId } = useParams()

  const {
    config,
    project,
    loadIssues,
    saveFilters,
    issues,
    sorting,
    selectedFilters,
    pagination,
    loading,
    loadingMore,
    issuesLoading,
    issuesLoaded,
    hasFilters,
  } = store

  const { assignees, owners, statuses } = selectedFilters

  useEffect(() => {
    const id = Number(projectId)

    const shouldLoadIssues = isAllDef(
      sorting.key,
      sorting.direction,
      project.id,
      assignees,
      owners,
      statuses,
    )

    if (shouldLoadIssues) {
      pagination.setFirstPage()
      saveFilters()
      loadIssues(id)
    }
  }, [
    loadIssues,
    projectId,
    sorting.key,
    sorting.direction,
    project.id,
    assignees,
    owners,
    statuses,
    pagination,
    saveFilters,
  ])

  const onBackClick = () => navigate(`/projects`)

  const onSearch = () => {
    const id = Number(projectId)
    if (id) {
      loadIssues(id)
    }
  }

  const Header = (
    <>
      <BackButton onClick={onBackClick} />
      <HeaderWithControl
        className="px-3"
        after={
          <GearIcon
            className="project-settings-icon"
            onClick={() => navigate(`/projects/${projectId}/settings`)}
          />
        }
      >
        {project?.title}
      </HeaderWithControl>
    </>
  )

  const Tasks = (
    <TaskList
      items={issues}
      loaded={issuesLoaded}
      loading={issuesLoading}
      hasFilters={hasFilters}
      loadingMore={loadingMore || issuesLoading}
      timeZone={config?.timeZoneOffset}
    />
  )

  return (
    <TaskListBase
      loading={loading}
      filterKeys={filterKeys}
      Header={Header}
      Tasks={Tasks}
      Footer={<TaskCreateFooter />}
      onSearch={onSearch}
      contentClassName="task-list-content"
    />
  )
})

export default Project
